import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {INLINES} from '@contentful/rich-text-types'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  Segment,
  Header,
  Image,
  Icon,
  Label,
  Divider,
  Button,
  Grid,
  Container
} from "semantic-ui-react";
import ReactPlayer from "react-player/youtube";
import Seo from "../components/seo";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";


const Main = ({ data }) => {
  const {
    description,
    tags,
    name,
    primaryContact,
    fileUrl,
    contentType,
  } = data.contentfulContentPieces;

  const descriptionJson = JSON.parse(description.raw)
  const headShotImage = getImage(primaryContact.headshot.gatsbyImage)


  console.log('primaryContact', primaryContact, headShotImage)
  //These are the render options for documentToReactComponents. It replaces internal links (based on starting with `/`) with React <Link> components
  const renderOptions = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        console.log(node, children)
        const { uri } = node.data
        if (uri.startsWith(`/`)) {
          return <Link to={uri}>{children}</Link>
        } else {
          return <a href={uri} target="_BLANK" rel="noreferrer">{children}</a>
        }
      }
    }
  }

  const RenderDownloadButton = () => {
    if (contentType.includes("Webinar")) return null;
    return (
      <Button primary onClick={trackDownload} as="a" href={fileUrl} target="__blank">
        {/* <OutboundLink href={fileUrl} target="__blank" style={{color: "white"}}> */}
          <Icon name="cloud download" />
          Download Report
        {/* </OutboundLink> */}
      </Button>
    );
  };

  const trackDownload = e => {
    console.log('track', e, name, fileUrl)
    typeof window !== "undefined" && window.gtag("event", "file_download", { type: 'download', name, fileUrl })
  }

  return (
    <Layout>
      <Seo title={name} description={documentToPlainTextString(descriptionJson)} />
      <Header as="h1">{name}</Header>
      <Divider />
      <Grid stackable>
        <Grid.Column width={12}>
          {tags &&
            tags.map(tag => (
              <Label key={tag} tag>
                {tag}
              </Label>
            ))}
          <Segment basic>
            {contentType.includes("Webinar") && (
              <>
                <ReactPlayer url={fileUrl} controls={true} />
                <br />
              </>
            )}
            {description && documentToReactComponents(descriptionJson, renderOptions)}
            <RenderDownloadButton />
          </Segment>
        </Grid.Column>
        <Grid.Column width={4}>
          <Segment>
            <Container>
              <Segment textAlign="center" basic>
                <Image circular centered>
                  <GatsbyImage
                    image={primaryContact.headshot.gatsbyImage}
                   
                    alt={primaryContact.name}
                  />
                </Image>
              </Segment>
              <Header as="h3">
                <Header.Content>
                  {primaryContact.name}
                  <Header.Subheader>{primaryContact.title}</Header.Subheader>
                </Header.Content>
              </Header>

              <span>
                {documentToReactComponents(JSON.parse(primaryContact.bio.raw))}
                <a as="button" color="green" href={"mailto:" + primaryContact.email + "?subject="+name}>
                  <Icon name="mail" />
                  Contact
                </a>
              </span>
            </Container>
          </Segment>
        </Grid.Column>
      </Grid>
    </Layout>);
}
export default Main

export const query = graphql`
  query($slug: String!) {
    contentfulContentPieces(slug: { eq: $slug }) {
      country
      date: releaseDate(formatString: "MMM D, 'YY")
      description {
        raw
      }
      name
      fileUrl
      tags
      slug
      contentType
      primaryContact {
        name
        email
        title
        bio {
          raw
        }
        headshot {
          gatsbyImage(height: 100, width: 100, cropFocus: FACES)
        }
      }
    }
  }
`;
